import React from 'react';
import styled from 'styled-components';
import { HiLightningBolt } from 'react-icons/hi';

const StyledButton = styled.button`
  background: #6b71ff;
  box-shadow: 0px 2px 10px rgba(25, 1, 52, 0.12);
  border-radius: 16px;
  width: 242px;
  height: 48px;
  margin: 12px 0;

  &:hover {
    background: #4e53db;
  }

  &:active {
    background: #353ab7;
  }

  &:disabled {
    background: #c9c9c9;
  }

  @media (min-width: 768px) {
    margin: 24px 0;
  }
`;

const InnerText = styled.span`
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.25px;
  color: #ffffff;
`;

const StyledIcon = styled(HiLightningBolt)`
  color: white;
  padding-left: 12px;
`;

type ButtonProps = {
  onClick: () => void;
};

const Button: React.FC<ButtonProps> = ({ onClick }) => (
  <StyledButton onClick={onClick}>
    <InnerText>Recargar medidor</InnerText>
    <StyledIcon />
  </StyledButton>
);

export default Button;

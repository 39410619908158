import React from 'react';
import styled from 'styled-components';
import TapLogo from '@/components/generics/svg/TapLogo';
import EdenorMide from '../generics/svg/edenorMide';

const TitleText = styled.div`
  --text-spacing: 12px;
  width: 100%;
  font-weight: bold;
  color: #ffffff;
  padding: var(--text-spacing) 0;
`;

const Wrapper = styled.div`
  background: linear-gradient(12.03deg, #8c9eff -11.5%, #14d2b9 100.72%);
  display: flex;
  flex-direction: column;
  padding: 18px;
  top: 0;
  min-height: 680px;
`;

const LogoWrapper = styled.div`
  height: 90px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 20px;
`;

const MainText = styled(TitleText)`
  font-size: 28px;
  line-height: 36px;
`;

const SecondaryText = styled(TitleText)`
  font-size: 24px;
  line-height: 32px;
`;

const WidthWrapper = styled.div`
  @media (min-width: 768px) {
    max-width: 500px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const Header: React.FC = ({ children }) => (
  <Wrapper>
    <LogoWrapper>
      <TapLogo />
      <EdenorMide />
    </LogoWrapper>
    <WidthWrapper>
      <MainText as="h2">¡No te quedes sin luz!</MainText>
      <SecondaryText>
        Te regalamos $50 para recargar tu medidor Edenor prepago
      </SecondaryText>
      {children}
    </WidthWrapper>
  </Wrapper>
);

export default Header;

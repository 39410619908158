import React from 'react';
import styled from 'styled-components';
import { PageProps } from 'gatsby';
import { navigateToNextView } from '@/utils';

import { BenefitsText } from '@/components/generics/styled';
import BenefitsArray from '@/components/home/benefitsArray';
import DownloadLinks from '@/components/home/downloadLinks';
import Instructions from '@/components/home/instructions';
import Footer from '@/components/home/footer';
import Header from '@/components/home/header';
import Button from '@/components/home/button';
import Layout from '@/components/layout';

const MainWrapper = styled.main`
  background: #edf3fb;
  padding: 50px 16px 0px;
`;

const WidthWrapper = styled.div`
  @media (min-width: 768px) {
    max-width: 500px;
    margin: auto;
  }
`;

const handleOnClick = () => navigateToNextView();

const Home: React.FC<PageProps> = () => (
  <Layout>
    <Header>
      <Button onClick={handleOnClick} />
      <Instructions />
    </Header>
    <MainWrapper>
      <WidthWrapper>
        <BenefitsText>
          Recargá siempre con Tap y disfrutá de todos los beneficios que tenemos
          para vos.
        </BenefitsText>
        <BenefitsArray />
        <BenefitsText>
          Descargá Tap y ganá otros $400 en tu próxima recarga
        </BenefitsText>
        <DownloadLinks />
      </WidthWrapper>
    </MainWrapper>
    <Footer />
  </Layout>
);

export default Home;

import React from 'react';

const TapLogo: React.FC = () => (
  <svg
    width="133"
    height="45"
    viewBox="0 0 133 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30.2197 30.0904C26.0011 30.0904 22.5852 26.661 22.5852 22.4257C22.5852 18.1904 26.0011 14.761 30.2197 14.761L41.1208 14.761C38.6942 11.2566 34.6809 8.97028 30.1077 8.97028C22.7159 8.97028 16.7053 14.9859 16.7053 22.4257C16.7053 29.8655 22.7159 35.8811 30.1077 35.8811C34.6623 35.8811 38.6942 33.5948 41.1208 30.0904L30.2197 30.0904Z"
      fill="white"
    />
    <path
      d="M69.16 32.3923C66.1675 32.3923 64.1725 30.7311 64.1725 26.9104V16.1129H71.3212V11.96H64.1725V3.65417H59.5175V27.0766C59.5175 33.7212 63.5075 36.8774 69.16 36.8774C70.9887 36.8774 72.6512 36.5452 73.9812 35.8807L71.9862 31.8939C71.155 32.2262 70.1574 32.3923 69.16 32.3923Z"
      fill="#002350"
    />
    <path
      d="M104.737 41.1969V24.2531C104.737 21.7614 105.402 19.6018 106.4 17.7746C107.564 15.7812 109.06 14.2861 110.889 13.1233C112.717 11.9605 114.879 11.4622 117.372 11.4622C119.866 11.4622 122.027 11.9605 123.856 13.1233C125.685 14.2861 127.347 15.7812 128.345 17.7746C129.509 19.768 130.007 21.9275 130.007 24.2531C130.007 26.7448 129.509 28.9043 128.511 30.7316C127.514 32.725 126.017 34.2201 124.355 35.2168C122.526 36.3796 120.531 36.8779 118.37 36.8779C116.541 36.8779 114.879 36.5457 113.382 35.7151C111.886 34.8845 110.556 33.8878 109.559 32.725V41.1969H104.737ZM117.372 32.8911C118.869 32.8911 120.365 32.5589 121.529 31.7283C122.692 30.8977 123.69 29.901 124.521 28.5721C125.186 27.2432 125.519 25.7481 125.519 24.2531C125.519 22.5919 125.186 21.0969 124.521 19.768C123.856 18.439 122.859 17.4423 121.529 16.6118C120.365 15.7812 118.869 15.4489 117.372 15.4489C115.876 15.4489 114.38 15.7812 113.216 16.6118C112.052 17.4423 111.055 18.439 110.224 19.768C109.559 21.0969 109.226 22.5919 109.226 24.2531C109.226 25.9143 109.559 27.4093 110.224 28.5721C110.889 29.901 111.886 30.8977 113.216 31.7283C114.546 32.3928 115.876 32.8911 117.372 32.8911Z"
      fill="#002350"
    />
    <path
      d="M100.249 35.8813H96.0924V30.8979H95.9262C94.4299 34.2202 91.105 36.5458 86.6162 36.5458C80.465 36.5458 74.48 32.0607 74.48 23.921C74.48 16.6119 79.8 11.2961 87.4475 11.2961C95.0949 11.2961 100.249 16.4457 100.249 24.0871V35.8813ZM95.7599 23.921C95.7599 19.1036 92.4349 15.6152 87.4475 15.6152C82.46 15.6152 79.135 19.1036 79.135 23.921C79.135 28.7383 82.46 32.2268 87.4475 32.2268C92.4349 32.3929 95.7599 28.7383 95.7599 23.921Z"
      fill="#002350"
    />
    <path
      d="M41.23 38.2067C41.23 39.8679 40.0662 41.1968 38.7362 41.1968H3.6575V3.65455H38.7362C40.0662 3.65455 41.23 4.98348 41.23 6.64464V14.7843H44.8875V6.64464C44.8875 2.99009 42.0612 0 38.7362 0H0V44.8514H38.7362C42.0612 44.8514 44.8875 41.8613 44.8875 38.2067V30.067H41.23V38.2067Z"
      fill="#002350"
    />
    <g opacity="0.5">
      <g opacity="0.5">
        <g opacity="0.5">
          <path
            opacity="0.5"
            d="M27.4312 29.5492L36.9417 33.9717C38.6042 32.975 39.862 31.7345 41.0258 30.0733L30.2574 30.0475C29.2599 30.0475 28.2624 29.8814 27.4312 29.5492Z"
            fill="#002350"
          />
        </g>
      </g>
    </g>
    <path
      d="M30.091 17.7744H31.9198C34.4136 17.7744 36.5748 19.7678 36.5748 22.4257C36.5748 25.0835 34.5798 27.0769 31.9198 27.0769H30.091C27.5973 27.0769 25.436 25.0835 25.436 22.4257C25.436 19.7678 27.431 17.7744 30.091 17.7744Z"
      fill="#002350"
    />
    <defs />
  </svg>
);

export default TapLogo;
